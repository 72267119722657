import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Icon,
  Image,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Header } from 'features-unauthenticated/header';
import ImgAnalyze from 'images/corail/illustration_corail_home.svg';
import { Link as ReactRouterLink } from 'react-router-dom';
import LogoCorailSVG from 'images/corail/logo_corail_couleur.svg';
import { VscMail } from 'react-icons/vsc';
import { FiExternalLink } from 'react-icons/fi';

/**
 * nouvelle landing Hublot
 *
 */
export function LandingPageCorail() {
  return (
    <Flex
      direction="column"
      align="center"
      maxW={{ xl: '1200px' }}
      m="0 auto"
      overflow={'auto'}
    >
      <Header />
      <Hero></Hero>
    </Flex>
  );
}

function Hero() {
  return (
    <Flex
      gap={30}
      justify={{ base: 'space-around', lg: 'space-evenly' }}
      w="full"
      wrap={{ base: 'wrap', xl: 'nowrap' }}
    >
      <VStack alignSelf={'center'} my={10} alignItems={'center'}>
        <Image
          alignSelf={{ base: 'self-start' }}
          src={LogoCorailSVG}
          w={'341px'}
          h="132px"
          alt="logo corail"
          p={0}
          ml={{ base: '-25px', md: '-25px' }}
          mt={{ base: 0, md: '0px', lg: '5px' }}
        />
        <Text
          as={'h2'}
          color="hublot.bleuElectrique"
          my={{ base: '2', lg: '5', xl: '8' }}
        >
          {/* Déchiffrer. Diagnostiquer. Décider. */}
          Le tableau de bord
        </Text>
        <Center>
          <Button
            as={ReactRouterLink}
            to="app"
            p="4"
            border="1px solid"
            borderColor="hublot.bleuElectrique"
            my={4}
          >
            Accès abonnés
          </Button>
        </Center>

        <Flex
          align="center"
          justify="space-between"
          wrap="wrap"
          w="100%"
          p={{ base: 1, md: 3 }}
          mt={6}
          gap={2}
          borderTopWidth={'2px'}
          borderTopColor={'hublot.grisClair'}
          flexDirection={{ base: 'column' }}
          display={{ base: 'none', md: 'block' }}
        >
          <HStack>
            <Text as="span">Pour demander vos identifiants</Text>
            <VscMail />
            <Link to="mailto:contact@compas-tis.com">contactez-nous</Link>
          </HStack>
          <Link
            isExternal={true}
            href="http://www.lecompas.fr/mentions-legales/"
          >
            Mentions légales
            <Icon as={FiExternalLink} mx="2px" />
          </Link>
          <Text>Tous droits réservés © Le Compas 2023</Text>
        </Flex>
      </VStack>
      <Image
        src={ImgAnalyze}
        alt="logo analyze"
        width={{ base: '300px', lg: '400px' }}
      />
      <Flex
        align="center"
        justify="space-between"
        w="100%"
        p={{ base: 4 }}
        mt={6}
        borderTopWidth={'2px'}
        borderTopColor={'hublot.grisClair'}
        flexDirection={{ base: 'column' }}
        display={{ base: 'block', md: 'none' }}
      >
        <VStack>
          <HStack>
            <Text as="span">Pour demander vos identifiants</Text>
            <VscMail />
            <Link to="mailto:contact@compas-tis.com">contactez-nous</Link>
          </HStack>
          <Link
            isExternal={true}
            href="http://www.lecompas.fr/mentions-legales/"
          >
            Mentions légales
            <Icon as={FiExternalLink} mx="2px" />
          </Link>
          <Text>Tous droits réservés © Le Compas 2023</Text>
        </VStack>
      </Flex>
    </Flex>
  );
}
