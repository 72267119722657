import {
  Flex,
  Container,
  Heading,
  Stack,
  Button,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import logo from './logo_vdm_utile_bleu_large_fond_transparent.gif';
import logoBweb from 'images/logo_compas.png';
import { MdCheckCircle } from 'react-icons/md';
import './style.css';
export default function CallToActionWithIllustration() {
  return (
    <Container maxW={'5xl'}>
      <Flex w={'full'} justifyContent="space-between">
        <img src={logo} width={'15%'} alt="logo cd94" />
        <a
          href="http://www.lecompas.fr/"
          target="_blank"
          rel="noreferrer"
          title="Un outil réalisé par Le Compas"
        >
          <img
            src={logoBweb}
            style={{ height: '47px', width: '235px', margin: '12px' }}
            alt="logo bweb"
          />
        </a>
      </Flex>
      <Stack
        textAlign={'center'}
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
        bg={'compasLogo.grisTresClair'}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
          lineHeight={'110%'}
          borderBottom={'4px solid'}
          borderBottomColor="compasLogo.jaune"
        >
          Babord web
          {/* <Text as={"span"} color={"orange.400"}>
            en 1 clic
          </Text> */}
        </Heading>
        <Stack spacing={6} direction={'row'}>
          <Button
            as={ReactRouterLink}
            to="app"
            rounded={'full'}
            px={6}
            color="white"
            bg={'rgb(0,157,224)'}
            border="1px solid rgb(0,157,224)"
            _hover={{
              bg: 'white',
              color: 'rgb(0,157,224)',
            }}
          >
            Se connecter
          </Button>
          {/* <Button rounded={"full"} px={6}>
            Learn more
          </Button> */}
        </Stack>
        <List textAlign={'left'} align={'center'}>
          <ListItem>
            Un outil simple, rapide et intuitif pour accéder aux données de vos
            territoires pour :
          </ListItem>
          <ListItem>
            <ListIcon
              as={MdCheckCircle}
              color="compasLogo.jauneFonce"
            ></ListIcon>
            Mettre en partage l’ensemble des traitements et analyses,
          </ListItem>
          <ListItem>
            <ListIcon
              as={MdCheckCircle}
              color="compasLogo.jauneFonce"
            ></ListIcon>
            Explorer une base de données socio-démographiques riche et
            actualisée,
          </ListItem>
          <ListItem>
            <ListIcon
              as={MdCheckCircle}
              color="compasLogo.jauneFonce"
            ></ListIcon>
            Rechercher les informations essentielles pour réaliser un portrait
            synthétique de vos territoires,
          </ListItem>
          <ListItem>
            <ListIcon
              as={MdCheckCircle}
              color="compasLogo.jauneFonce"
            ></ListIcon>
            Personnaliser vos analyses thématiques et territoriales,
          </ListItem>
        </List>
      </Stack>
    </Container>
  );
}
